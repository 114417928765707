import React, { useState, useRef, useContext, useEffect } from 'react';
import { Scoped, k } from 'kremling';
import { Stack, Paper, IconButton, Typography, Checkbox, Box, Divider, Menu, MenuItem, Popper, Button as ButtonMui } from '@mui/material';
import Tooltip from 'rc-tooltip';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { LicenseInfo } from '@mui/x-license-pro';
import { RemoveIcon, AddIcon, PanCursorIcon, FilterIcon, SettingsIcon, Calendar24HoursIcon, Calendar30DaysIcon, Calendar7DaysIcon, CalendarAllIcon, CalendarDateRangeIcon, RightCaretIcon, StoppedIcon, DuplicateIcon, PausedIcon, MultiCompanyPrompt, snackbarService } from 'components/mui';
import { StatusMui } from 'components';
import { NodeModal, DynamicModalPublished } from './node/node-modal';
import { UserStateContext } from 'context/user-state-context';
import { JourneyApp } from './journey-app';
import { duplicateJourney as duplicateJourneyAPI, getRevisionAnalytics, patchRevision, deleteRevision } from 'shared/common.api';
import { CustomersListModal } from './customers-list-modal.component';
import { ModalContext } from 'context/modal-context';
import { MODES } from './node/node-utils';
import { CustomersListModalSQL } from './customers-list-modal-sql.component';
LicenseInfo.setLicenseKey(MUIX_API);
const getStatus = revision => {
  if (revision?.deleted_when) {
    return 'stopped';
  } else if (revision?.closed_when) {
    return 'closed';
  } else if (revision?.paused_when) {
    return 'paused';
  } else if (revision?.published_when) {
    return 'published';
  }
  return 'draft';
};
const FILTER_TYPES = [{
  key: 'all',
  name: 'All',
  icon: <CalendarAllIcon />
}, {
  key: '24hours',
  name: '24 Hours',
  icon: <Calendar24HoursIcon />
}, {
  key: '7days',
  name: '7 Days',
  icon: <Calendar7DaysIcon />
}, {
  key: '30days',
  name: '30 Days',
  icon: <Calendar30DaysIcon />
}];
export const ReadOnlyCanvas = ({
  revision,
  allRevisions,
  setRevision,
  history,
  nodes
}) => {
  // App state items
  const canvasRef = useRef(null);
  const appRef = useRef(null);
  const {
    hasPermission,
    hasMultiPermission
  } = useContext(UserStateContext);
  const {
    confirm,
    show
  } = useContext(ModalContext);
  const [showGrid, setShowGrid] = useState(() => localStorage.getItem('journeyShowGrid') === 'true');
  const [zoom, setZoom] = useState(1);

  // Analtyics state items
  const [analytics, setAnalytics] = useState();
  const [analyticsFilter, setAnalyticsFilter] = useState({
    type: '30days'
  });
  const [analyticsEl, setAnalyticsEl] = useState();
  const [analyticsDateEl, setAnalyticsDateEl] = useState();
  const [analyticsCalendarCache, setAnalyticsCalendarCache] = useState([]);

  // Settings menu state items
  const [settingsEl, setSettingsEl] = useState(null);
  const [settingsItems, setSettingsItems] = useState([]);

  // Customer list node state items
  const [customersListNodeId, setCustomersListNodeId] = useState(null);
  const [customersListNodeIdSQL, setCustomersListNodeIdSQL] = useState(null);
  const isLatest = revision.published_when && (allRevisions.length === 1 || revision.id === allRevisions[1].id);
  let publishedRevisions = [];
  if (revision.published_when) {
    //Only show other publsihed revisions if the revision we're looking at is published
    publishedRevisions = allRevisions.filter(r => r.published_when);
  }

  // Initialize app and fetch all journey nodes
  useEffect(() => {
    appRef.current = new JourneyApp(canvasRef.current, false, on);
    return () => appRef.current.destroy();
  }, []);
  useEffect(() => {
    appRef.current.setNodeLibrary(nodes);
  }, [nodes]);

  // Sync revision with app also set the proper settings menu
  useEffect(() => {
    if (revision) {
      appRef.current.setRevision(revision);
      const settingsItems = [];
      if (hasPermission('journey.add_journey')) {
        settingsItems.push(<MenuItem key="duplicate" onClick={duplicateJourney}>
            <DuplicateIcon fill="#000" />
            <Typography sx={{
            pl: '12px'
          }}>Duplicate Journey </Typography>
          </MenuItem>);
      }
      if (hasPermission('journey.change_journey')) {
        if (!!revision.paused_when && isLatest) {
          settingsItems.push(<MenuItem key="unpause" onClick={unpauseJourney}>
              <PausedIcon fill="#000" />
              <Typography sx={{
              pl: '12px'
            }}>Unpause Journey</Typography>
            </MenuItem>);
        } else if (!revision.paused_when && !revision.deleted_when) {
          settingsItems.push(<MenuItem key="pause" onClick={pauseJourney}>
              <PausedIcon fill="#000" />
              <Typography sx={{
              pl: '12px'
            }}>Pause Journey</Typography>
            </MenuItem>);
        }
        if (!revision.deleted_when && settingsItems.length > 0) {
          settingsItems.push(<Divider key="divider" />);
        }
        if (!revision.deleted_when) {
          settingsItems.push(<MenuItem key="delete" onClick={endJourney}>
              <StoppedIcon fill="#000" />
              <Typography sx={{
              pl: '12px'
            }}> End Journey</Typography>
            </MenuItem>);
        }
      }
      setSettingsItems(settingsItems);
    }
  }, [revision, hasPermission]);

  // Fetch analytics every 30 seconds after the last fetch.
  // This use effect is fancy and cleans up after itself (e.g., cancels fetches when the component is unmounted)
  useEffect(() => {
    if (!revision || !analyticsFilter) return;
    let controller;
    let timeout;
    let didUnmount = false;
    if (revision.published_when) {
      // LOAD STATS
      const loadStats = (nextDelay = 500) => {
        const params = {};
        if (analyticsFilter.type === '24hours') {
          params['created_after'] = moment().subtract(1, 'days').format('YYYY-MM-DD');
        } else if (analyticsFilter.type === '7days') {
          params['created_after'] = moment().subtract(7, 'days').format('YYYY-MM-DD');
        } else if (analyticsFilter.type === '30days') {
          params['created_after'] = moment().subtract(30, 'days').format('YYYY-MM-DD');
        } else if (analyticsFilter.type === 'custom') {
          if (analyticsFilter.startDate) {
            params['created_after'] = analyticsFilter.startDate.format('YYYY-MM-DD');
          }
          if (analyticsFilter.endDate) {
            params['created_before'] = analyticsFilter.endDate.add(1, 'days').format('YYYY-MM-DD');
          }
        }
        const promise = getRevisionAnalytics(revision.id, params);
        controller = promise.controller;
        promise.then(({
          data
        }) => setAnalytics(data)).catch(() => {}).then(() => {
          if (!didUnmount) {
            // increase the delay by 1 second up to 5 seconds
            timeout = setTimeout(() => loadStats(Math.min(nextDelay + 1000, 5000)), nextDelay);
          }
        });
      };
      loadStats();
    }
    return () => {
      // On unmount, revision switch, or filter change. Clear out all the long running stuff.
      didUnmount = true;
      clearTimeout(timeout);
      if (controller) {
        controller.abort();
      }
    };
  }, [revision, analyticsFilter]);

  // Sync analytics counts
  useEffect(() => {
    appRef.current.setAnalytics(analytics);
  }, [analytics]);

  // Sync zoom level
  useEffect(() => {
    appRef.current.setZoom(zoom);
  }, [zoom]);

  // Sync show grid to app and storage
  useEffect(() => {
    appRef.current.setShowGrid(showGrid);
    localStorage.setItem('journeyShowGrid', `${showGrid}`);
  }, [showGrid]);
  const on = (event, obj) => {
    // Events from the app.
    if (event === 'OPEN_CUSTOMER_LIST') {
      setCustomersListNodeId(obj.id);
    }
    if (event === 'OPEN_CUSTOMER_LIST_SQL') {
      setCustomersListNodeIdSQL(obj.id);
    } else if (event === 'EDIT_PARAM') {
      if (!!obj && !obj.componentParams) {
        /**
         * Old Node Modal
         * This is used for nodes that are not using the new `componentParams` and are still using the old `component`.
         * Also this is used for editing node links... Node links should probably be moved to its own component
         */
        appRef.current.setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(NodeModal, {
          readOnly: true,
          node: obj,
          linkEdit: null,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: zoom
        }).catch(e => console.error(e)).then(() => appRef.current.setMode(MODES.PAN));
      } else if (!!obj && !!obj.componentParams) {
        /**
         * New Dynamic Node Modal
         * This modal automatically generates the modal based on the configuration.
         * This is the published version
         */
        appRef.current.setMode(MODES.EDIT_PARAM);
        const {
          left: clientX,
          top: clientY
        } = canvasRef.current.getBoundingClientRect();
        show(DynamicModalPublished, {
          node: obj,
          stageX: appRef.current.movableContainer.x,
          stageY: appRef.current.movableContainer.y,
          clientX,
          clientY,
          scale: zoom
        }).catch(e => console.error(e)).then(() => appRef.current.setMode(MODES.PAN));
      }
    }
  };
  const unpauseJourney = () => {
    setSettingsEl(null);
    confirm({
      title: 'Unpause Journey',
      message: `Are you sure you want to unpause this journey?`,
      submitText: 'Confirm'
    }).then(() => {
      patchRevision(revision.id, {
        paused_when: null
      }).then(({
        data
      }) => {
        setRevision(data);
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const pauseJourney = () => {
    setSettingsEl(null);
    confirm({
      title: 'Pause Journey',
      message: `Are you sure you want to pause this journey?`,
      submitText: 'Confirm'
    }).then(() => {
      patchRevision(revision.id, {
        paused_when: DateTime.local().toUTC().toISO()
      }).then(({
        data
      }) => {
        setRevision(data);
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const duplicateJourney = () => {
    setSettingsEl(null);
    confirm({
      title: 'Duplicate Journey',
      message: `Are you sure you want to duplicate this journey?`,
      submitText: 'Confirm'
    }).then(() => {
      if (hasMultiPermission('journey.change_journey')) {
        // Do multi select
        return show(MultiCompanyPrompt, {
          title: 'Duplicate Journey',
          permission: 'journey.change_journey'
        });
      }
    }).then(companyIds => {
      duplicateJourneyAPI(revision.journey, companyIds).then(res => {
        if (!companyIds && res.data.id) {
          // We only duplicated one so go there.
          history.push(`/customer-journeys/${res.data.id}`);
        }
        snackbarService.popup({
          type: 'success',
          message: 'Journey successfully duplicated.'
        });
      }).catch(e => {
        console.error(e);
        snackbarService.popup({
          type: 'error',
          message: 'There was an unknown error trying to duplicate the journey. Please try again.'
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  const endJourney = () => {
    setSettingsEl(null);
    confirm({
      title: 'End Journey',
      message: <>
          <p>Are you sure you want to end this journey? All customers in it will stop immediately and no new customers will enter it.</p>
          <strong>This cannot be undone!</strong>
        </>,
      submitText: 'Confirm'
    }).then(() => {
      deleteRevision(revision.id).then(() => {
        setRevision({
          ...revision,
          deleted_when: DateTime.local().toUTC().toISO()
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  return <Scoped css={css}>
      <Stack direction="row" className="inner-wrapper">
        {publishedRevisions.length > 1 && <div className="revision-picker">
            <select className="form-control inline ml-3" value={revision.id} onChange={e => setRevision(allRevisions.find(r => r.id === e.target.value))}>
              {publishedRevisions.map((revision, index, original) => <option value={revision.id} key={revision.id}>
                  Revision #{original.length - index} Published: {moment(revision.published_when).format('MMM D, YYYY')}
                </option>)}
            </select>
          </div>}
        <div ref={canvasRef} className="journey-canvas"></div>

        <Stack className="journey-top-right" direction="row" spacing="30px">
          <StatusMui status={getStatus(revision)} />
          {revision.published_when && <>
              <IconButton onClick={e => setAnalyticsEl(!analyticsEl ? e.currentTarget : null)}>
                <FilterIcon size={21} fill="#53A6D6" />
              </IconButton>

              {settingsItems.length > 0 && <>
                  {(isLatest || !revision?.deleted_when) && <IconButton onClick={e => setSettingsEl(!settingsEl ? e.currentTarget : null)}>
                      <SettingsIcon />
                    </IconButton>}
                </>}
            </>}
        </Stack>

        <div className="journey-bottom-left">
          <Tooltip placement="top" overlay="Move Canvas : s">
            <IconButton>
              <PanCursorIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div className="journey-bottom-right">
          <Paper>
            <Stack direction="row" spacing="30px">
              <Stack sx={{
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'center'
            }} direction="row" spacing="10px">
                <Checkbox checked={showGrid} value={showGrid} onChange={() => setShowGrid(!showGrid)} className="show-grid" />

                <Typography>Show Grid</Typography>
              </Stack>
              <Stack sx={{
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'center'
            }} direction="row" spacing="10px">
                <IconButton onClick={() => setZoom(zoom > 0.6 ? Math.round((zoom - 0.1) * 10) / 10 : 0.5)} disabled={zoom === 0.5}>
                  <RemoveIcon />
                </IconButton>
                <Typography> {zoom * 100}%</Typography>
                <IconButton onClick={() => setZoom(zoom < 1 ? Math.round((zoom + 0.1) * 10) / 10 : 1)} disabled={zoom === 1}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Paper>
        </div>
      </Stack>

      <Popper anchorEl={analyticsEl} open={!!analyticsEl} sx={{
      zIndex: 200
    }} onClose={() => setAnalyticsEl(null)} placement="left-end">
        <Paper elevation={0} sx={{
        borderRadius: '16px',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
      }}>
          <Box sx={{
          p: '12px'
        }}>
            <Typography variant="tableHeader">Filter Statistics</Typography>
          </Box>
          {FILTER_TYPES.map(filter => <Stack direction="row" sx={{
          cursor: 'pointer',
          p: '12px',
          display: 'flex',
          alignItems: 'center'
        }} key={filter.key} spacing="12px" onClick={e => {
          setAnalyticsFilter({
            type: filter.key
          });
          setAnalyticsEl(null);
        }}>
              <Box> {filter.icon}</Box>
              <Typography> {filter.name}</Typography>
            </Stack>)}
          <Box sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }} className="d-flex column space-between">
            <Stack direction="row" spacing="12px" sx={{
            p: '12px',
            display: 'flex',
            alignItems: 'center'
          }} onClick={e => setAnalyticsDateEl(e.target)}>
              <CalendarDateRangeIcon />
              <Typography>Date Range </Typography>
            </Stack>
            <RightCaretIcon />
          </Box>
        </Paper>
      </Popper>

      <Popper anchorEl={analyticsDateEl} sx={{
      zIndex: 200
    }} open={!!analyticsDateEl} onClose={() => setAnalyticsDateEl(null)} placement="left">
        <Paper elevation={0} sx={{
        borderRadius: '16px',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
      }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{
          after: 'after date',
          before: 'before date'
        }} sx={{
          padding: '10px'
        }}>
            <DateRangeCalendar value={analyticsCalendarCache} onChange={newValue => setAnalyticsCalendarCache(newValue)} />
          </LocalizationProvider>
          <Stack sx={{
          pb: '12px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse'
        }} direction="row">
            <ButtonMui onClick={() => {
            setAnalyticsDateEl(null);
            setAnalyticsEl(null);
            setAnalyticsFilter({
              type: 'custom',
              startDate: analyticsCalendarCache[0],
              endDate: analyticsCalendarCache[1]
            });
          }}>
              {' '}
              <Typography sx={{
              textTransform: 'none'
            }}> Ok</Typography>
            </ButtonMui>
            <ButtonMui onClick={() => setAnalyticsDateEl(null)}>
              {' '}
              <Typography sx={{
              textTransform: 'none'
            }}> Cancel </Typography>
            </ButtonMui>
          </Stack>
        </Paper>
      </Popper>

      <Menu anchorEl={settingsEl} open={!!settingsEl} onClose={() => setSettingsEl(null)} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}>
        {settingsItems}
      </Menu>

      <CustomersListModal nodeId={customersListNodeId} onClose={() => setCustomersListNodeId(null)} />
      <CustomersListModalSQL nodeId={customersListNodeIdSQL} onClose={() => setCustomersListNodeIdSQL(null)} />
    </Scoped>;
};
const css = {
  styles: `[kremling="i21"] body,body[kremling="i21"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i21"] .inner-wrapper,[kremling="i21"].inner-wrapper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;
}

[kremling="i21"] button,button[kremling="i21"] {
  background-color: #fff;
}

[kremling="i21"] button:hover,button[kremling="i21"]:hover {
  background-color: #daefff;
}

[kremling="i21"] .journey-canvas,[kremling="i21"].journey-canvas {
  flex-grow: 1;
}

[kremling="i21"] .revision-picker,[kremling="i21"].revision-picker {
  position: absolute;
  top: 1rem;
  z-index: 10;
}

[kremling="i21"] .journey-bottom-right,[kremling="i21"].journey-bottom-right {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem 0;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}
[kremling="i21"] .journey-bottom-right .show-grid,[kremling="i21"].journey-bottom-right .show-grid {
  color: #1d252d;
}
[kremling="i21"] .journey-bottom-right .show-grid.Mui-checked,[kremling="i21"].journey-bottom-right .show-grid.Mui-checked {
  color: #53a6d6;
}
[kremling="i21"] .journey-bottom-right .show-grid .MuiSvgIcon-root,[kremling="i21"].journey-bottom-right .show-grid .MuiSvgIcon-root {
  font-size: 22px;
}
[kremling="i21"] .journey-bottom-right div.control,[kremling="i21"].journey-bottom-right div.control {
  width: 2.2rem;
  height: 2.2rem;
}
[kremling="i21"] .journey-bottom-right div.count,[kremling="i21"].journey-bottom-right div.count {
  font-size: 1.2rem;
  color: #606060;
  font-weight: 700;
  height: 2.2rem;
  line-height: 2.2rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  width: 4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  text-align: center;
}

[kremling="i21"] .journey-bottom-left,[kremling="i21"].journey-bottom-left {
  position: absolute;
  padding: 0 1rem 1rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  user-select: none;
}
[kremling="i21"] .journey-bottom-left>button,[kremling="i21"].journey-bottom-left>button {
  margin-right: 1rem;
  background: #daefff;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

[kremling="i21"] .journey-top-right,[kremling="i21"].journey-top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
}
[kremling="i21"] .journey-top-right button,[kremling="i21"].journey-top-right button {
  border: 1px solid #D9D9D9;
}
[kremling="i21"] .journey-top-right button path,[kremling="i21"].journey-top-right button path {
  fill: rgb(83, 166, 214);
}
[kremling="i21"] .journey-top-right>button,[kremling="i21"] .journey-top-right .dropdown,[kremling="i21"].journey-top-right>button,[kremling="i21"].journey-top-right .dropdown {
  margin-left: 1rem;
}

[kremling="i21"] .journey-top-right__toggle,[kremling="i21"].journey-top-right__toggle {
  margin-right: 1rem;
  position: relative;
}
[kremling="i21"] .journey-top-right__toggle>button,[kremling="i21"].journey-top-right__toggle>button {
  margin-right: -0.1rem;
  border-radius: 0;
  z-index: 0;
}
[kremling="i21"] .journey-top-right__toggle>button:first-child,[kremling="i21"].journey-top-right__toggle>button:first-child {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
[kremling="i21"] .journey-top-right__toggle>button:last-child,[kremling="i21"].journey-top-right__toggle>button:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}`,
  id: 'i21',
  namespace: 'kremling'
};