import React, { useState, useEffect, useContext } from 'react';
import { Scoped, k } from 'kremling';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { Button } from '../../components/button/button.component';
import { getHubspotToken, signInSSO } from '../../shared/auth.api';
import { UserStateContext } from 'context/user-state-context';
const setLogo = () => ({
  __html: cinchLogo
});
export function Support(props) {
  const [formErrors, setFormErrors] = useState({});
  const [email, setEmail] = useState('');
  const [redirectURL, setRedirectURL] = useState('');
  const [password, setPassword] = useState('');
  const {
    isValidToken
  } = useContext(UserStateContext);
  const handleSubmit = event => {
    event.preventDefault();
    signInSSO({
      email,
      password
    }).then(({
      data
    }) => {
      window.location.href = `https://support.cinch.io/_hcms/mem/jwt?redirect_url=${redirectURL}&jwt=${data.token}`;
    }).catch(({
      response
    }) => {
      if (response && response.data) {
        setFormErrors(response.data);
      }
    });
    return false;
  };
  const handleUserInput = event => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };
  useEffect(() => {
    if (isValidToken() && redirectURL) {
      // Redirect to Hubspot
      getHubspotToken().then(({
        data
      }) => {
        window.location.href = `https://support.cinch.io/_hcms/mem/jwt?redirect_url=${redirectURL}&jwt=${data.token}`;
      });
    }
  }, [redirectURL]);
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setRedirectURL(params.get('redirect_url'));
  }, []);
  return <Scoped css={css}>
      <div className="sign-in__wrapper">
        <div className="sign-in">
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <div className="mb-5">
                <div className="logo" dangerouslySetInnerHTML={setLogo()} />
              </div>
            </div>

            {!!formErrors.non_field_errors ? <div>
                <p className="invalid-input">
                  {formErrors.non_field_errors[0]}
                </p>
              </div> : null}

            <div className="form-group">
              <label>Email Address</label>
              <input id="iSignInEmail" className="form-control" name="email" onChange={handleUserInput} type="email" value={email} email="true" autoFocus />
              {!!formErrors.email ? <div>
                  <p className="invalid-input">{formErrors.email[0]}</p>
                </div> : null}
            </div>

            <div className="form-group mb-5">
              <label>Password</label>
              <input id="iSignInPassword" className="form-control" name="password" onChange={handleUserInput} value={password} type="password" />
              {!!formErrors.password ? <div>
                  <p className="invalid-input">{formErrors.password[0]}</p>
                </div> : null}
            </div>
            <div className="pt-5">
              <Button id="bSignInSignIn" type="submit" actionType="primary" block large>
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Scoped>;
}
const css = {
  styles: `[kremling="i8"] body,body[kremling="i8"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i8"] .sign-in__wrapper,[kremling="i8"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fff;
}

[kremling="i8"] .sign-in,[kremling="i8"].sign-in {
  padding: 5rem 2rem;
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i8"] .sign-in,[kremling="i8"].sign-in {
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i8"] .logo,[kremling="i8"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}

[kremling="i8"] .invalid-input,[kremling="i8"].invalid-input {
  margin-top: 0px;
  color: red;
}`,
  id: 'i8',
  namespace: 'kremling'
};